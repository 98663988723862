@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
ol,
a,
div,
button,
Button,
label,
table,
Table,
th,
td {
  font-family: 'Inter', sans-serif !important;
  transition: all 0.3s ease-in-out !important;
}

:root {
  /* COLORS */
  --blue-primary: #174EA1;
  --c-green-secondary: #74C0B6;
  --white: #ffffff;
  --black: #000000;
  overflow-x: hidden;

  /* FONT SIZES */
  --fs-12: 12px;
  --fs-13: 13px;
  --fs-14: 14px;
  --fs-15: 15px;
  --fs-16: 16px;
  --fs-17: 17px;
  --fs-18: 18px;
  --fs-19: 19px;
  --fs-20: 20px;
  --fs-21: 21px;
  --fs-22: 22px;
  --fs-23: 23px;
  --fs-24: 24px;
  --fs-25: 25px;
  --fs-26: 26px;
  --fs-27: 27px;
  --fs-28: 28px;

  /* FONT WEIGHTS */
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-bold: bold;

  /* TEXT CASES */
  --uppercase: uppercase;
  --lowercase: lowercase;
  --capitalize: capitalize;

  /* BORDER RADIUS */

  --br-3: 3px;
  --br-5: 5px;
  --br-10: 10px;
  --br-15: 15px;


}

html {
  scroll-behavior: smooth !important;
}

a {
  text-decoration: none !important;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}