:root {
  /* COLORS */
  --blue-primary: #000000;
  /* --c-green-secondary: #DC0002; */
  --c-green-secondary: #b80505;
  --white: #ffffff;
  --black: #000000;
  --gray: #a0a0a0;
  --blue: #1034a6;

  /* FONT SIZES */
  --fs-12: 12px;
  --fs-13: 13px;
  --fs-14: 14px;
  --fs-15: 15px;
  --fs-16: 16px;
  --fs-17: 17px;
  --fs-18: 18px;
  --fs-19: 19px;
  --fs-20: 20px;
  --fs-21: 21px;
  --fs-22: 22px;
  --fs-23: 23px;
  --fs-24: 24px;
  --fs-25: 25px;
  --fs-26: 26px;
  --fs-27: 27px;
  --fs-28: 28px;
  --fs-29: 29px;
  --fs-30: 30px;
  --fs-31: 31px;
  --fs-32: 32px;

  /* FONT WEIGHTS */
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-bold: bold;

  /* TEXT CASES */
  --uppercase: uppercase;
  --lowercase: lowercase;
  --capitalize: capitalize;

  /* BORDER RADIUS */

  --br-3: 3px;
  --br-5: 5px;
  --br-10: 10px;
  --br-15: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--c-green-secondary);
  border-radius: 20px;
  border: 2px solid #ffffff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--c-green-secondary);
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: #000052;
}

::selection {
  color: var(--white);
  background: #000052;
}

.inspectionOrders::-webkit-scrollbar {
  width: 5px !important;
  height: 7px;
}

.filterSidebar .MuiDrawer-paper::-webkit-scrollbar {
  display: none;
}

.inpection_order_btn {
  width: 35px;
}

.inspection_Bio h4 {
  font-size: 12px;
  font-weight: bolder;
}

.inspection_Bio p {
  font-size: 12px;
}


.inspection_Order h4 {
  font-size: 14px;
}

.inspection_Order p {
  font-size: 14px;
}

.bottom-name {
  width: 95%;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.fw-500 {
  font-weight: var(--fw-500) !important;
}

.fw-600 {
  font-weight: var(--fw-600) !important;
}

.fw-700 {
  font-weight: var(--fw-700) !important;
}

.fw-800 {
  font-weight: var(--fw-800) !important;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.cursorPointer {
  cursor: pointer !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.focusUnset :is(:focus, :focus-visible, :focus-within) {
  outline: unset !important;
  box-shadow: unset !important;
  border: unset !important;
}

.toggleLeftIcon:is(:focus, :focus-visible, :focus-within) {
  outline: unset !important;
  box-shadow: unset !important;
  border: unset !important;
}

.preloaderMain {
  background-color: var(--white);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.innerloaderMain {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loaderGif {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.loaderLogo {
  height: 60px;
  width: 100%;
  object-fit: contain;
}

.color-primary {
  color: var(--blue-primary) !important;
}

.color-secondary {
  color: var(--c-green-secondary) !important;
}

.color-white {
  color: var(--white) !important;
}

.color-black {
  color: var(--black) !important;
}

.color-blue {
  color: var(--c-green-secondary) !important;
}

.bgPrimary {
  background-color: var(--blue-primary) !important;
}

.bgSecondary {
  background-color: var(--c-green-secondary) !important;
}

.bgRed10 {
  background-color: #c1c1c147;
  backdrop-filter: blur(2px);
}

.bgWhite {
  background-color: var(--white) !important;
}

.bgBlack {
  background-color: var(--black) !important;
}

.bgFooter {
  background-color: #efefef !important;
}

.boxShadow {
  box-shadow: 0 0 20px 0 #00000015 !important;
}

.Fw-popups .MuiModal-backdrop {
  background-color: #0000005e !important;
  backdrop-filter: blur(5px) !important;
}

.topOptions :is(a, p) {
  font-size: var(--fs-12);
  color: var(--black);
  cursor: pointer;
}

.emailBar {
  background-color: var(--white);
  border-radius: 10px;
  padding: 10px 0;
}

.emailBar button {
  border-radius: 10px;
}

.emailBar input {
  border-radius: 10px;
  background-color: var(--white);
  border: 0;
}

.subsFrom {
  width: 85%;
  margin: auto;
  border-radius: 20px;
}

.searchBar {
  background-color: #ffffff9c;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.accordion-body {
  max-height: 300px;
  overflow-y: scroll;
}

.searchBar button {
  border-radius: 10px;
}

.searchBar input {
  border-radius: 10px;
  background-color: transparent;
  border: 0;
  padding: 25px 15px;
  color: #000 !important;
}

.searchBar input::placeholder {
  color: #000000cf !important;
}

.searchBar input:focus {
  box-shadow: unset !important;
}

.searchBar span {
  /* border-top-right-radius: 20px;
        border-bottom-right-radius: 20px; */
  background-color: transparent;
  border: 0;
  box-shadow: inset -2px 2px 3px 0 #00000038;
}

.fw-btn {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
  text-transform: var(--capitalize) !important;
  border-radius: var(--br-5) !important;
  border: 1px solid var(--c-green-secondary) !important;
}

.successAnim {
  height: 23px !important;
  width: auto;
}

.fw-primary {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
  text-transform: var(--capitalize) !important;
  border-radius: var(--br-5) !important;
  border: 1px solid var(--c-green-secondary) !important;
}

.fw-primary:hover {
  background-color: transparent !important;
  color: var(--c-green-secondary) !important;
}

.fw-secondary {
  background-color: var(--c-green-secondary);
  color: var(--white);
  text-transform: var(--capitalize);
  border-radius: var(--br-5);
  border: 1px solid var(--c-green-secondary);
}

.fw-secondary:hover {
  background-color: transparent;
}

.signup_banner {
  height: 700px;
  width: 100%;
  object-fit: cover;
}

.FW-logo {
  height: 50px;
  width: 100%;
  object-fit: contain;
}

.FW-drawer-logo {
  height: 40px;
  width: 100%;
  object-fit: contain;
}

.callUs h5 {
  font-size: var(--fs-13);
}

.callUs a {
  text-decoration: none;
  font-size: var(--fs-14);
  font-weight: var(--fw-500);
  opacity: 90%;
}

.preHeader_inspection a {
  text-decoration: none;
  font-size: var(--fs-15);
  font-weight: var(--fw-600);
  color: var(--white);
}

.preHeader_inspection a:hover {
  color: var(--white);
}

.preHeader_authBtns :is(a, p) {
  color: var(--white);
  text-decoration: none;
  font-size: var(--fs-15);
}

.preHeader_authBtns a .fa-chevron-down {
  font-size: var(--fs-12);
}

.preHeader_authBtns p {
  opacity: 50%;
}

.preHeader_authBtns button {
  color: var(--white);
}

.preHeader_authBtns button:hover {
  color: var(--white);
}

.userDetails p {
  color: var(--white) !important;
  opacity: unset !important;
  padding: 2px 10px;
  font-size: var(--fs-14);
}

.userDetails p:hover {
  opacity: unset !important;
}

.userDetails:hover {
  cursor: pointer;
}

.userDetails:hover .userDetails-subMenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
  position: absolute;
  transition: all 0.3s ease-in-out;
  z-index: 9999;
}

.userDetails-subMenu {
  position: absolute;
  top: 10%;
  width: 180px;
  right: 0;
  visibility: hidden;
  opacity: 0;
  background-color: var(--white);
  box-shadow: 0 10px 10px 0 rgb(18 54 82 / 40%);
  z-index: 5;
  border-radius: 10px;
}

.userDetails-subMenu li {
  list-style-type: none;
  width: 90% !important;
  padding: 3px 7px;
}

.userDetails-subMenu li:hover {
  list-style-type: none;
  width: 90% !important;
  padding: 3px 7px;
  border-radius: var(--br-3);
  cursor: pointer;
  background-color: #0000001a;
}

.userDetails-subMenu li:hover a {
  text-decoration: none;
  cursor: pointer;
}

.userDetails-subMenu li a {
  text-decoration: none;
  color: var(--black);
  font-size: var(--fs-13);
  font-weight: 500;
}

.fw-navbar li .nav-link {
  color: var(--black) !important;
  border-bottom: 2px solid transparent;
  text-transform: var(--capitalize);
  font-size: var(--fs-15) !important;
  font-weight: var(--fw-500);
  padding: 5px 10px !important;
}

.fw-navbar li .nav-link:hover {
  color: var(--c-green-secondary) !important;
  /* border-bottom: 2px solid var(--c-green-secondary) ; */
}

.navbar-nav .nav-link.active {
  color: var(--c-green-secondary) !important;
  /* border-bottom: 2px solid var(--c-green-secondary) ; */
  font-weight: var(--fw-700);
}

.serviceDrop {
  cursor: pointer;
}

.serviceDrop:hover {
  cursor: pointer;
  border-bottom: 2px solid transparent !important;
}

.serviceDrop-main:hover .Services-subMenu {
  visibility: visible;
  opacity: 1;
  top: 100%;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

.Services-subMenu {
  position: absolute;
  top: 200%;
  width: 230px;
  left: 10%;
  visibility: hidden;
  opacity: 0;
  background-color: var(--white);
  box-shadow: 3px 10px 30px 0 rgb(18 54 82 / 40%);
  border-radius: var(--br-10);
  z-index: 5;
}

.Services-subMenu li {
  list-style-type: none;
  margin-bottom: 6px;
  margin-right: 10px;
  width: 90% !important;
  padding: 3px 7px;
  color: var(--blue-primary);
  font-size: var(--fs-13);
  font-weight: 500;
}

.Services-subMenu li:hover {
  list-style-type: none;
  margin-bottom: 6px;
  margin-right: 10px;
  width: 90% !important;
  padding: 3px 7px;
  /* background-color: var(--blue-primary); */
  border-radius: var(--br-3);
  cursor: pointer;
  background-color: #0000001a;
}

.Services-subMenu li:hover a {
  text-decoration: none;
  cursor: pointer;
}

.Services-subMenu li a {
  text-decoration: none;
  color: var(--blue-primary);
  font-size: var(--fs-14);
  font-weight: 500;
}

/* .bg-img {
    background-image: url("../../images/bgTop-img.png");
    height: 40vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
} */

.topSec1 h3 {
  color: var(--white);
  font-size: 40px;
}

.topSec1 h5 {
  color: var(--white);
  font-size: var(--fs-22);
}

.bg-post {
  background-image: url("../../images/bg-car.png");
  height: 90vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.post-fw-ad :is(h3, p) {
  color: var(--white);
  text-transform: var(--capitalize);
}

.post-fw-ad h3 {
  font-size: var(--fs-28);
  padding-bottom: 15px;
}

.post-fw-ad p {
  font-size: var(--fs-16);
  margin: 0;
}

.post-fw-ad button {
  background-color: var(--white);
  padding: 10px 30px;
  color: var(--blue-primary);
  font-weight: var(--fw-600);
  text-transform: var(--capitalize);
  border: 2px solid var(--white);
  border-radius: var(--br-5);
}

.post-fw-ad button:hover {
  background-color: transparent;
  padding: 10px 30px;
  color: var(--white);
  font-weight: var(--fw-600);
  text-transform: var(--capitalize);
  border: 2px dashed var(--white) !important;
}

.section-titles {
  margin-bottom: 15px;
  padding-bottom: 0;
  font-weight: bolder;
  font-size: var(--fs-28);
  text-transform: var(--capitalize);
}

/* .sliderIndicator_main .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 6px;
    border-radius: 2px;
    opacity: 1;
} */

.sliderIndicator .carousel-indicators .active {
  background-color: var(--c-green-secondary);
}

.sliderIndicator .carousel-control-next,
.sliderIndicator .carousel-control-prev {
  opacity: 1 !important;
}

.sliderIndicator_main {
  opacity: unset !important;
  background-color: var(--c-green-secondary);
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 30px;
}

.sliderIndicator {
  height: 25px;
}

.categoryInner .carousel-item a {
  text-decoration: none;
}

.assistanceBtn {
  border-radius: 15px;
}

.makeBtn {
  /* height: 10px; */
  border-radius: 5px;
  border: 1px solid lightgray !important;
}

.makeBtn img {
  height: 80px;
}

.makeLogos {
  height: 100px;
  object-fit: contain;
  width: 100%;
  padding: 10px;
}

.makeBtn div {
  border: 1px solid lightgray !important;
  background-color: rgb(247, 246, 246) !important;
}

.makeBtn:hover {
  background-color: #ced4d44a;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  /* border:none !important; */
}

.makeBtn:hover h6 {
  color: var(--c-green-secondary) !important;
}

.makeBtn h6 {
  color: var(--black);
  text-decoration: none !important;
  text-align: center;
  text-transform: var(--capitalize);
  font-size: var(--fs-18);
}

.categpryBtn {
  height: 130px;
  border-radius: 15px;
  border: 1px solid lightgray !important;
}

.categpryBtn button {
  padding: 0 !important;
}

.categpryBtn:hover {
  background-color: #ced4d44a;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  /* border:none !important; */
}

.categpryBtn:hover h6 {
  color: var(--black) !important;
}

.categpryBtn img {
  height: 50px;
  width: 75px;
  object-fit: contain;
}

.categoryInner {
  width: 95%;
  margin: auto;
}

.adPost {
  border: 0.5px solid rgb(226, 225, 225);
  border-radius: 10px;
  /* box-shadow: 1px 7px 14px -8px rgba(0,0,0,0.45); */
}

.adPost img {
  height: 150px !important;
  cursor: pointer !important;
  /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
  border-radius: 6px;
  box-shadow: 3px 3px 10px 1px #00000045;
}

.adBody h5 {
  font-size: var(--fs-18);
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.adBody h4 {
  font-size: var(--fs-17);
}

.biddingBg1 {
  background-image: url("../../images/bg-1.png");
}

.biddingBg2 {
  background-image: url("../../images/bg-2.png");
}

.biddingBg3 {
  background-image: url("../../images/bg-3.png");
}

.sellBanner img {
  /* background-image: url('../../images/sell-to-famewheels-Banner.jpg'); */
  width: 100%;
}

.AdbBanner {
  background-image: url("../../images/Frame 14.png");
  border-radius: unset !important;
  background-size: contain !important;
  min-height: 300px !important;
  max-height: 100%;
  height: auto;
  background-repeat: no-repeat;
}

.biddingBannerMain {
  background-image: url("../../images/bidding-banner.jpg");
  border-radius: unset !important;
  background-size: contain !important;
  height: 407px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.imgStyle {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 205px;
  border-radius: 20px;
  background-position: 100%;
}

.imgStyle h2 {
  font-size: var(--fs-22);
  width: 50%;
}

.ad-share-btns {
  top: 0;
  right: 2%;
}

.ad-share-btns button {
  background-color: var(--white);
  color: var(--c-green-secondary);
  margin: 4px;
  border-radius: 100%;
  padding: 4px 9px;
}

.ad-share-btns button i {
  font-size: 18px;
  line-height: unset;
}

.ad-share-btns button:hover {
  color: var(--c-green-secondary);
  transform: scale(1.02);
}

.vehicleDetails_price p {
  font-size: 14px;
  margin: 0;
}

.vehicleDetails_price strong {
  color: var(--c-green-secondary);
  font-size: var(--fs-26);
}

.adlocation {
  font-size: var(--fs-12);
  text-transform: capitalize;
}

.ad-Title {
  color: var(--black) !important;
  font-size: var(--fs-15);
}

.adPrice {
  color: #5d5d5d !important;
  font-size: var(--fs-14);
}

.adBody .ad-info {
  background-color: var(--c-green-secondary);
  border-radius: var(--br-5);
}

.adPost img {
  height: 190px;
  width: 100%;
  object-fit: cover;
}

.ad-info p {
  margin: 0;
  color: var(--white);
  text-transform: var(--capitalize);
}

.ext-info {
  font-size: var(--fs-12);
  width: 100%;
  color: var(--black);
  visibility: hidden;
  opacity: 0;
  display: none;
}

.adPost:hover .ext-info {
  visibility: visible;
  opacity: 1;
  display: block;
}

.adBTn {
  background-color: lightgrey;
  width: 100%;
  /* background-color: var(--c-green-secondary); */
  color: var(--black);
  /* border: 2px solid var(--blue-primary); */
  /* visibility: hidden;
   opacity: 0;
   display: none; */
}

.adBTn:hover {
  /* background-color: transparent; */
  background-color: var(--c-green-secondary);
  color: var(--white);
}

/* .adPost:hover .adBTn {
    visibility: visible;
    opacity: 1;
    display: block;
} */

.featured-badge {
  background-color: var(--c-green-secondary);
  color: var(--white);
  font-weight: var(--fw-600);
  border-radius: 3px;
  display: inline-block;
  width: fit-content;
  z-index: 10;
  font-size: var(--fs-13);
  margin-top: 5px !important;
  margin-left: 5px !important;
}

/* .featured-badge:before {
    height: 0;
    width: 0;
    border-top: 15px solid #000000bb ;
    border-left: 13px solid transparent;
    bottom: -14.5px;
    position: absolute;
    content: "";
    left: -0.1px;
  } */

.biddingDropDwon {
  background-color: #f6f6f6 !important;
  font-size: var(--fs-15);
  border-radius: 10px;
}

.borderLeft2 {
  border-left: 3px solid #fff !important;
}

.carMakerBtn {
  background-color: var(--white);
  height: 140px !important;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #00000023;
}

.carMakerBtn img {
  height: 100px;
}

.carMakerBtn h6 {
  color: #000000c2;
  text-decoration: none !important;
  text-align: center;
  font-size: var(--fs-14);
  text-transform: var(--uppercase);
}

.contactus_main {
  background-image: url("../../images/contactus-bg.png");
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contactus_main h3 {
  color: var(--white);
  font-size: 35px;
}

.contactus_num {
  color: var(--white);
  text-decoration: none;
  font-size: var(--fs-30);
}

.contact_area img {
  height: 70px;
}

.contact_area p {
  color: var(--white);
  font-weight: 300;
  font-size: var(--fs-14);
}

.footer-services :is(ul, li, a) {
  color: #3d3d3d;
  text-transform: var(--capitalize);
  font-weight: 500;
  font-size: var(--fs-12);
}

.footer-services h5 {
  font-weight: var(--fw-600);
}

.footer-services ul {
  padding-left: 0px;
}

.footer-services ul li {
  list-style-type: none;
  /* line-height: 2rem; */
  /* letter-spacing: 1px; */
  cursor: pointer;
  font-weight: 500;
  font-size: var(--fs-12);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: var(--white);
  padding: 20px;
  border-radius: 10px;
  max-height: 95%;
  height: 100%;
  overflow-y: scroll;
}

.modalBody_area h3 {
  color: var(--c-green-secondary);
  font-size: var(--fs-30);
  font-weight: var(--fw-700);
  text-transform: var(--capitalize);
}

.modalBody_area h6 {
  color: var(--blue-primary);
  font-weight: var(--fw-600);
  font-size: var(--fs-18);
}

.modalBody_area h6 span {
  color: var(--c-green-secondary);
}

.redirectingBtn {
  text-transform: var(--capitalize) !important;
  color: var(--c-green-secondary) !important;
}

.modalBody_area .connect_para {
  color: var(--c-green-secondary);
  font-size: var(--fs-14);
  font-weight: var(--fw-300);
}

.Modal_postSection {
  box-shadow: 0 0 10px 0px #0000001f;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Modal_postSection h6 {
  color: var(--blue-primary);
  font-weight: var(--fw-600);
  font-size: var(--fs-23);
}

.Modal_postSection img {
  height: 120px;
  width: 100%;
  object-fit: contain;
}

.Modal_postSection ul li {
  list-style-type: none;
  text-align: start;
  color: var(--blue-primary);
  font-weight: var(--fw-400);
  line-height: 2rem;
}

.Modal_postSection ul li span {
  color: var(--c-green-secondary);
}

.Modal_postSection ul .postNote {
  color: var(--c-green-secondary);
  font-size: var(--fs-14);
  line-height: unset;
  height: 50px;
}

.ModalPostBtn {
  width: 100%;
  text-transform: unset;
}

.privacyText {
  color: var(--c-green-secondary);
  font-size: var(--fs-14);
  margin: unset;
}

.privacyText a {
  color: var(--blue-primary);
}

.loginsignUpBtn {
  background-color: var(--blue-primary);
  padding: 10px 30px;
  border: unset !important;
  border-radius: var(--br-10);
  color: var(--white);
}

.have_account {
  color: var(--c-green-secondary);
  font-size: var(--fs-15);
  margin: unset;
}

.have_account button {
  color: var(--blue-primary);
  text-decoration: none;
  background-color: unset;
  border: 0;
}

.login_inputStyle {
  color: var(--c-green-secondary);
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--c-green-secondary);
  border-radius: var(--br-5) !important;
}

.login_inputStyle span {
  background-color: transparent;
  border: unset !important;
  height: 50px;
}

.login_inputStyle span i {
  color: var(--c-green-secondary);
}

.login_inputStyle input {
  border: unset !important;
  height: 50px;
  color: var(--blue-primary);
}

.login_inputStyle input::placeholder {
  font-weight: var(--fw-400);
  font-size: var(--fs-14);
}

.form-control:focus,
.form-control:focus-visible {
  color: var(--blue-primary);
  background-color: unset !important;
  border-color: var(--c-green-secondary) !important;
  outline: unset !important;
  box-shadow: unset !important;
}

.login_forgot a {
  color: var(--blue-primary);
  text-decoration: none;
  font-size: var(--fs-14);
  font-weight: var(--fw-600);
}

.login_forgot a:hover {
  color: var(--c-green-secondary);
}

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.infoMainConatiner {
  background-color: #f2f3f3;
}

.termsMainHeading {
  color: var(--c-green-secondary);
  font-weight: 600;
}

.infoMainBody {
  background-color: white;
}

.infoMainBody>h5 {
  color: var(--c-green-secondary);
  font-size: 15px;
}

.infoMainBody>p {
  font-size: 13px;
}

.aboutus_cover {
  width: 100%;
}

.aboutus_profile {
  border-right: 5px solid var(--c-green-secondary);
}

.aboutus_photos {
  height: 150px;
}

.about_profile_exp {
  font-size: 13px;
}

.about_profile_name {
  font-size: 16px;
  font-weight: 600;
  color: var(--c-green-secondary);
  margin-top: 0;
}

.about_profile_desig {
  font-size: 12px;
  font-weight: 600;
  /* color: var(--c-green-secondary); */
  margin-bottom: 0;
}

.contactus_info {
  border-right: 5px solid var(--c-green-secondary);
}

.contactus_form {
  border-right: 5px solid var(--c-green-secondary);
}

.contactus_info>h4 {
  color: var(--c-green-secondary);
  font-weight: 700;
  font-size: 18px;
}

.contactus_info>h5 {
  color: var(--c-green-secondary);
  font-weight: 600;
  font-size: 16px;
  margin-top: 0;
}

.contactus_info>p {
  font-size: 14px;
  margin-bottom: 0;
}

.footerHeading {
  color: var(--black) !important;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

/* post css */

.postAd_bg {
  background-image: url("../../images/postAd_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 300px;
  background-size: contain;
}

.post-intro h4 {
  color: var(--blue-primary);
  font-size: var(--fs-23);
}

.post-intro p {
  color: var(--c-green-secondary);
  font-size: var(--fs-14);
}

.post-steps {
  background-color: var(--white);
  margin: 5px;
  border-radius: var(--br-10);
  padding: 15px 10px;
}

.post-steps img {
  height: 40px !important;
  width: 40px !important;
  object-fit: contain;
  margin-bottom: 10px;
}

.post-steps p {
  color: var(--black);
  font-size: var(--fs-15);
  font-weight: var(--fw-400);
  margin: 0;
}

.postInform {
  border-top: 6px solid transparent;
  box-shadow: 2px 2px 8px 2px #00000024;
}

.postInform:hover {
  border-top: 6px solid var(--c-green-secondary);
}

.postInform h4 {
  color: var(--c-green-secondary);
  font-weight: var(--fw-600);
  text-transform: var(--capitalize);
}

.postInform .warning {
  color: var(--c-green-secondary);
}

.postAdForm label,
.sidebarFilters label {
  color: var(--c-green-secondary);
}

.sidebarFilters label {
  font-size: 13px;
}

.postAdForm label {
  font-weight: 500;
}

.postAdForm .form-check-input:checked,
.sidebarFilters .form-check-input:checked {
  background-color: var(--c-green-secondary);
  border-color: var(--c-green-secondary);
}

.postAdForm .form-text {
  font-weight: 300;
  color: var(--black);
}

.postAdForm input::placeholder,
.sidebarFilters input::placeholder {
  color: #c4a0a0;
  font-weight: 300;
}

.postAdForm .input-group-text {
  background-color: var(--c-green-secondary);
  color: var(--c-green-secondary);
  font-weight: 600;
  padding: 3px 10px;
}

.postAdForm select option:nth-child(1) {
  color: #00000061;
}

.appointmentForm select option:nth-child(1) {
  color: #00000061;
}

.form-divider {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin: auto;
  max-width: 95%;
  opacity: 10%;
}

.dropzone {
  border: 2px dashed #0000006b;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  min-height: 210px !important;
  max-height: 100% !important;
  height: auto;
}

.dragging {
  background-color: var(--white);
}

.vehcileImages_uploadBtn {
  color: var(--blue-primary);
  font-size: var(--fs-18) !important;
  font-weight: var(--fw-500) !important;
}

.ImageError {
  color: var(--c-green-secondary);
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropHere {
  display: none;
  color: var(--blue-primary);
  padding-top: 20px;
  font-size: var(--fs-32);
  font-weight: var(--fw-400);
}

.dragging .dropHere {
  display: block;
}

.dragging .vehcileImages_uploadBtn,
.dragging p,
.dragging .uploadImage_inst,
.dragging .previewMain {
  display: none;
}

.dragging .ImageError {
  color: var(--c-green-secondary) !important;
}

.previewMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.vehicleImagePreview {
  position: relative;
  margin-right: 30px;
  margin-bottom: 20px;
}

.vehicleImagePreview img {
  height: 100px;
  width: 150px;
  object-fit: contain;
  border: 2px solid var(--c-green-secondary);
}

.vehicleImagePreview button {
  background-color: var(--c-green-secondary);
  border-radius: 100%;
  padding: 0px 5px 2px 4px !important;
  margin: 0 !important;
  height: 28px;
  position: absolute;
  top: -10px;
  right: -15px;
}

.vImgLimit {
  font-size: var(--fs-14);
  color: var(--blue-primary);
}

.uploadImage_inst {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.uploadImage_inst img {
  height: 13px;
  width: 13px;
  object-fit: contain;
  margin-top: 10px;
  margin-right: 20px;
}

.uploadImage_inst p {
  color: #9a9a9a;
  font-weight: 300;
  text-align: start;
  font-size: var(--fs-14);
}

.postFormBtn {
  padding: 10px 80px;
}

/* post css */

/* my Ads */

.myUserDetail_Main {
  background-image: url("../../images/profileDetail-banner.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 235px;
}

.myUserDetail_Main .profilePic {
  height: 130px;
  object-fit: contain;
}

.myUserDetail_Main h4 {
  color: var(--blue-primary);
}

.myUserDetail_btn {
  color: var(--c-green-secondary);
  font-weight: var(--fw-500);
}

.myUserDetail_btn:hover {
  color: var(--blue-primary);
  text-decoration: underline;
}

.btnDivider {
  background-color: var(--c-green-secondary);
  width: 2px;
  height: 15px;
}

.MyAds_main {
  background-color: #ececec;
}

.myAds_options ul {
  padding: 0;
  margin: 0;
}

.myAds_options ul li {
  list-style-type: none !important;
}

.myAds_options ul li button {
  background-color: var(--white);
  color: var(--c-green-secondary);
  border-radius: 0;
  width: 100%;
  margin-bottom: 5px;
  text-align: start;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px !important;
}

.defectName {
  font-size: 14px !important;
  color: #000 !important;
}


.bgRedLight {
  background: rgb(255, 230, 230);
}

.bgGreenLight {
  background: rgb(230, 255, 230);
}

.imageViewBtn {
  font-size: 13px !important;
  padding: 0 5px !important;
  width: 50%;
  display: flex !important;
  justify-content: flex-start !important;
  margin-top: 3px !important;

}

.printBtn {
  box-shadow: unset;
  background-color: #d3d3d3 !important;
}

.myAds_options ul li button:hover {
  background-color: var(--c-green-secondary);
  color: var(--white);
}

.BtnActive button {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
  font-weight: 500 !important;
}

.No_ads {
  background-color: var(--white);
  min-height: 200px;
  max-height: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.No_ads_loading {
  background-color: var(--white);
  min-height: 200px;
  max-height: auto;
  height: auto;
}

.No_ads p {
  color: var(--blue-primary);
  text-transform: capitalize;
  font-size: var(--fs-20);
}

.MyAdsArea_main {
  min-height: 200px;
  max-height: auto;
  height: auto;
}

.myAd {
  background-color: var(--white);
  box-shadow: 0 0 20px 0 #00000021;
  border-radius: var(--br-10);
}

.Myads_photos img {
  height: 150px;
  width: 100%;
  object-fit: contain;
  background-color: #161616;
  border-radius: var(--br-5);
}

.vehicledDetail_icon {
  height: 18px;
  width: 18px;
  object-fit: contain;
}

.detailsFeature {
  font-size: 15px;
}

.detailsFeature i {
  color: var(--black);
}

.vehicleDetails h4 {
  color: var(--black);
  font-size: 20px;
}

.vehicleDetails h5 {
  font-size: 18px;
}

.postlocation {
  color: var(--c-green-secondary) !important;
  font-size: 14px;
}

.vehicleDetails h6 {
  color: var(--c-green-secondary);
  font-weight: 400;
}

.adViews_area p {
  color: var(--c-green-secondary);
  font-size: var(--fs-13);
}

.adViews_area p svg {
  color: var(--blue-primary) !important;
  font-size: var(--fs-18);
}

.adstatus .live {
  background-color: #7db312;
}

.adstatus .pending {
  background-color: #ab7208;
}

.adstatus .rejected {
  background-color: #b30000;
}

.rejected_error {
  color: var(--black);
  font-size: var(--fs-14);
}

.adstatus :is(.live, .pending, .rejected) {
  display: inline-block;
  padding: 3px 10px 3px 10px;
  border-radius: 50px;
  color: var(--white);
  font-size: 14px;
}


.btntextSize {
  font-size: 13px;
  font-weight: 400;
}

/* my Ads */

/* used cars */
.used_main {
  background-image: url("../../images/profileDetail-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.vehicleDetails_title h4 {
  color: var(--black);
  font-weight: var(--fw-700);
  font-size: var(--fs-24);
}

.vehicleDetails_title p {
  color: var(--c-green-secondary);
  font-size: var(--fs-15);
}

.vehicleDetails_price {
  color: var(--black);
  font-weight: 400;
}

.vehicleDetails_price button {
  color: var(--c-green-secondary);
  background-color: #fffdfd;
  border: 1px solid #00000035;
}

.vehiclePhotos img {
  height: 350px;
  width: auto;
  object-fit: contain;
  background-color: var(--black);
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 6px;
  border-radius: 2px;
  opacity: 1;
  background-color: var(--white);
}

.carousel-indicators .active {
  background-color: var(--c-green-secondary);
  width: 25px;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}

.vehicleDetails_specs {
  border-radius: 15px;
}

.vehicleDetails_specs img {
  height: 30px;
}

.vehicleDetails_specs h6 {
  font-size: var(--fs-14);
}

.vehicleDetails_specs p {
  color: var(--blue-primary);
  font-weight: 500;
  font-size: var(--fs-16);
  padding-top: 5px;
  text-transform: capitalize;
}

.vehicleDetails_info {
  font-weight: 400;
}

.vehicleDetails_info div {
  font-size: var(--fs-17);
}

.vehicleDetails_info h4 {
  font-weight: 600;
  font-size: var(--fs-24);
}

.vehicleDetails_info strong {
  color: var(--c-green-secondary);
  font-weight: var(--fw-500);
}

.vehicle_description h4 {
  font-weight: 600;
  font-size: var(--fs-24);
}

.vehicle_description p {
  color: #272727;
  font-weight: 300;
  font-size: var(--fs-16);
}

.imgsGallery {
  display: flex;
  justify-content: start;
  background-color: #000;
  height: 100px;
}

.imgItem img {
  height: 100px;
  width: 90px;
  object-fit: contain;
  background-color: #292929;
  margin-right: 3px;
}

.moreImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px !important;
  width: 60px !important;
  background-color: #000000d6;
  cursor: pointer;
}

.moreImgs h6 {
  color: var(--white);
  font-weight: 500;
  font-size: var(--fs-22);
  cursor: pointer;
}

.liveBidsArea {
  border-radius: 15px;
  height: 410px;
}

.liveBidderDetail {
  height: 350px;
  overflow: scroll;
}

.biduserIcon {
  width: 180px !important;
}

.biduserIcon img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.biduserIcon i {
  color: #ffffff;
}

.biduserIcon p {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}

.BidsBg {
  background: rgb(0, 31, 182);
  background: linear-gradient(90deg,
      rgba(0, 31, 182, 1) 0%,
      rgba(111, 80, 237, 1) 35%,
      rgba(133, 164, 249, 1) 100%);
  padding: 5px 10px;
  border-radius: 10px;
}

.myBid {
  background: rgb(184, 5, 5);
  background: linear-gradient(90deg,
      rgba(184, 5, 5, 1) 0%,
      rgba(75, 11, 11, 1) 35%,
      rgba(12, 1, 20, 1) 100%);
}

.usersBid {
  background: rgb(79, 106, 238);
  background: linear-gradient(90deg,
      #4f6aee 0%,
      rgb(161, 142, 236) 35%,
      rgb(183, 200, 247) 100%);
}

.bidSticky {
  position: sticky;
  bottom: 0;
  top: 0;
}

/* .bidsFlow {
    background-color: #EAEAEA;
} */

.notBidsAvail {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.biddingTimerBar {
  background-color: var(--white) !important;
  border: 1px solid var(--c-green-secondary);
  height: 8px !important;
  border-radius: 10px;
}

.biddingTimerBar span {
  background-color: var(--c-green-secondary) !important;
}

.bidPriceInc {
  width: 45%;
  border: 3px solid var(--black);
  border-radius: 20px;
}

.bidPriceInc button {
  border: unset;
  background-color: var(--c-green-secondary);
  border-radius: 30px;
  padding: 2px 10px;
  color: var(--white);
  font-weight: 800;
}

.bidPriceInc button:disabled {
  opacity: 0.5;
}

.bidPriceInc span {
  font-weight: 600;
}

.sendMsg:hover {
  background-color: transparent !important;
  color: var(--c-green-secondary) !important;
  border: 1px solid var(--c-green-secondary);
}

.sellerInfo h6 {
  font-size: var(--fs-22);
  color: var(--black);
  font-weight: 700;
}

.sellerInfo h6 span {
  color: var(--c-green-secondary);
}

.sellerInfo img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.sellerInfo strong {
  font-size: var(--fs-17);
  color: #434343 !important;
}

.sellerInfo p {
  font-weight: 400;
}

.sellersInfoBTn button {
  background-color: var(--c-green-secondary) !important;
  width: 70%;
}

.VehiclePhotos_FullPreview .MuiModal-backdrop {
  background-color: #000000df !important;
}

.VehiclePhotos_PreviewCarousel img {
  height: 600px;
  width: auto;
  object-fit: contain;
}

.VehiclePhotos_FullPreview .carousel {
  position: unset !important;
}

.VehiclePhotos_PreviewCarousel .carousel-control-next,
.VehiclePhotos_PreviewCarousel .carousel-control-prev {
  top: 50% !important;
  height: 50px !important;
  width: fit-content;
  border-radius: 50px;
  padding: 35px 20px;
  background-color: var(--blue-primary);
}

.PreviewModal_CloseBtn {
  position: absolute;
  top: 0;
  right: 0;
}

.close_primary,
.close_primary:hover {
  background-color: var(--c-green-secondary);
  color: var(--white);
  border-radius: 50px;
  padding: 7px 8px;
}

.close_primary:focus {
  outline: unset;
}

.successModal h3 {
  color: var(--blue-primary);
}

.successModal :is(h5, p) {
  color: var(--c-green-secondary);
}

.inspectionMain {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.inspContent h6 {
  font-size: var(--fs-19);
  color: var(--black);
  font-weight: 700;
  text-transform: capitalize;
}

.inspContent p {
  font-size: var(--fs-14);
  font-weight: 300;
  color: var(--black);
}

.inspectionMain .inspectionServiecImg {
  height: 450px !important;
}

.mainBg {
  background-image: url("../../images/inspection-background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  flex-direction: column;
}

.vInsp_top h4 {
  font-size: 46px;
  font-weight: var(--fw-700);
}

.vInsp_top h6 {
  font-size: var(--fs-23);
  font-weight: var(--fw-400);
}

.inspectionForm {
  background-color: var(--white);
  border-radius: var(--br-15);
}

.react-datepicker__time-list-item--disabled {
  display: none !important;
}

.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected {
  background-color: var(--blue-primary) !important;
}

.inspectIcon {
  height: 50px !important;
  width: 50px !important;
  object-fit: contain;
  background-color: var(--c-green-secondary);
  padding-bottom: 3px;
  border-radius: 50%;
}

.inspectReportLogo {
  height: 60px !important;
  width: auto !important;
  padding-bottom: 3px;
  object-fit: contain;
}

.inspectReportCar {
  height: 250px !important;
  width: 350px !important;
  padding-bottom: 3px;
  object-fit: contain;
}

.car-progress {
  font-size: 12px;
  text-align: right;
  margin: 0;
  padding: 0;
}

.moreIconBtn i {
  border-radius: 30px;
  padding: 7px;
}

.progress-heading {
  font-weight: 700;
}

.borderbottom {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px dashed lightgrey;
}

.report-customer-info {
  font-size: 17px;
  text-transform: capitalize;
}

.CustomerData {
  font-size: 16px;
  color: #737373;
  text-transform: capitalize;
}

.AccorData {
  font-size: 16px;
  color: #666666;
  text-transform: capitalize;
  margin: 0;
}

.reportAccTab Button {
  text-transform: capitalize !important;
  color: var(--c-green-secondary) !important;
  border-color: var(--c-green-secondary) !important;
}

.reportAccTab h5 {
  color: #4e4e4e !important;
  font-weight: 600;
  font-size: 16px;
}

.ratingBar .progresValuePercent {
  top: 35%;
  font-size: 26px !important;
  left: 11px;
}

.progresValuePercent {
  position: absolute;
  top: 35%;
  left: 9px;
  right: 0px;
}

.carWrap {


  object-fit: contain;
  position: relative;

}

.accHeadIcons {
  height: 22px;
  width: auto;
  object-fit: contain;
}

.reportAccordins .accordion-item {
  border: unset !important;
}

.reportAccordins .accordion-body {
  max-height: 100%;
  overflow-y: unset;
}

.reportAccordins .accordion-button:not(.collapsed),
.reportAccordins .accordion-button {
  background-color: #dee2e6;
  margin-bottom: 10px;
  box-shadow: unset;
  border: 1px solid #dee2e6;
  border-radius: 7px;
}

.reportAccordins .accordion-item:first-of-type .accordion-button,
.reportAccordins .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 7px;
}

.reportAccordins .accordion-button::after {
  background-image: url("https://icons.veryicon.com/png/o/internet--web/prejudice/down-arrow-5.png") !important;
}

.reportAcc_Header i {
  color: var(--c-green-secondary);
}

.reportAcc_Header h5 {
  color: var(--c-green-secondary);
  font-weight: 600;
  text-transform: capitalize;
}

.reportAcc_Header h5:nth-child(1) {
  color: var(--black);
}

.sidebarMain {
  background-color: var(--white);
}

.accordion-button::after {
  background-image: url("https://www.freeiconspng.com/uploads/white-down-arrow-png-2.png") !important;
}

.accordion-button:focus {
  box-shadow: unset;
  border: unset;
}

.sidebarFilters button:focus {
  box-shadow: unset;
}

.sidebarFilters .accordion-item {
  border: unset;
  margin-bottom: 20px;
}

.sidebarFilters .accordion-body {
  background-color: #f5f5f5;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 4px 3px 10px 0 #00000021;
}

.sidebarFilters .accordion-body .form-check-input,
.sidebarFilters .accordion-body .form-check-label {
  cursor: pointer !important;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 10px;
}

.sidebarFilters .accordion-button {
  background-color: var(--c-green-secondary);
  font-weight: var(--fw-400);
  color: var(--white);
  border-radius: 10px !important;
  padding: 10px 15px;
  font-size: var(--fs-15);
}

.sidebarFilters .accordion-button:not(.collapsed) {
  background-color: var(--c-green-secondary);
  color: var(--white);
  font-weight: var(--fw-400);
  border-radius: 10px !important;
  padding: 10px 15px;
  font-size: var(--fs-15);
}

.inspectInc_Card {
  border-radius: var(--br-15);
  min-height: 170px;
  height: auto;
}

.inspectInc_Card h4 {
  font-weight: var(--fw-600);
  font-size: var(--fs-20);
}

.inspectInc_Card p {
  font-size: var(--fs-16);
}

.inspectionOrder_pagi .MuiToolbar-root p {
  margin: 0 !important;
}

.insReport {
  height: 450px !important;
  width: 100% !important;
  object-fit: contain !important;
}

.inspectionPricing {
  border-radius: var(--br-15);
}

.inspectionPricing img {
  height: 100px !important;
  width: 100% !important;
  object-fit: contain !important;
}

.inspectionPricing h2 {
  color: var(--blue-primary);
  font-size: var(--fs-20);
  font-weight: var(--fw-700);
  text-transform: var(--capitalize);
}

.priceFilter label {
  color: #182835;
  font-weight: var(--fw-600);
  margin-bottom: 5px;
  font-size: 14px;
  margin-right: 10px;
  width: 110px;
}

.priceAmount {
  width: 80px;
  font-size: 14px;
}

.priceFilter .rc-slider-handle {
  background-color: var(--c-green-secondary);
  opacity: 1;
}

.priceFilter .rc-slider-track {
  background-color: var(--c-green-secondary);
}

.priceFilter input {
  border: 1px solid #ccc8c8;
  border-radius: 3px;
}

.priceFilter span {
  font-weight: 500;
  font-size: 14px;
}

.sideBarSearch {
  font-size: 14px;
  background-color: var(--white);
}

.sideBarSearch:is(:focus, :focus-visible, :focus-within) {
  background-color: var(--white) !important;
}

.vehicleCondition .vclabel {
  font-weight: var(--fw-600);
}

.vehicleCondition .vcradio {
  display: flex;
  justify-content: space-evenly;
}

.MyAds_options .MuiPaper-root {
  box-shadow: 0 1px 3px 0px #0000001f;
  padding: 5px 0;
}

.MyAds_options .MuiPaper-root button {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  font-size: var(--fs-16) !important;
  color: #444343;
  border-radius: unset;
  width: 100%;
  text-align: start;
}

.MyAds_options .MuiPaper-root button:hover {
  background-color: #00000018;
}

/* bidding */

.biddingCard {
  border-radius: 15px;
}

.biddingCard .vehicleImg {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.vehicleInfo_Area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.vehicleTab2 {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* .vehicleBid_time .time {
    background-color: #262626;
    color: #ffefef;
    display: inline-block;
    border-radius: 5px;
} */

.time {
  font-size: var(--fs-22);
  font-weight: var(--fw-700);
  width: max-content;
}

.vehicleBid_More {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.vehicleBid_More h4 {
  padding-bottom: 15px;
}

.bid p {
  font-size: var(--fs-16);
}

.actionBids_btn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.bottomNavigation {
  z-index: 99999999;
}

.bottomArea {
  max-width: 992px;
  min-width: 300px;
  width: auto;
  margin: auto;
  box-shadow: 1px 0 12px 0 #0000001c;
}

.postBtn {
  position: relative !important;
  top: -15px;
}

.postBtn Button {
  background-color: var(--c-green-secondary) !important;
  border-radius: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  margin-bottom: 5px;
}

.postBtn svg {
  color: var(--white) !important;
  font-size: 30px !important;
}

.Nav-Btns Button {
  color: var(--black) !important;
  font-size: var(--fs-12);
  font-weight: 300 !important;
}

.Nav-Btns button svg {
  color: #ff4b4b;
}

.Nav-Btns p {
  font-size: var(--fs-12);
  margin: 0;
}

.d-response {
  display: flex !important;
  align-items: center;
}

.navbar-light .navbar-toggler {
  border: 0px solid transparent !important;
}

.searchBarArea {
  border-radius: 15px;
  width: 80vw;
}

.searchBg {
  background-image: url("../../images/Frame 9.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
}

.bottomNagivation_res {
  display: none !important;
}

.userDetails {
  display: block;
  position: relative;
}

/* modals */

.sm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  min-height: 10%;
  max-height: 95%;
  height: auto;
  overflow-y: scroll;
}






.myLogin {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 10px;
  min-height: 10%;
  max-height: 95%;
  height: auto;
  overflow-y: scroll;
}

.md-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  min-height: 10%;
  max-height: 95%;
  height: auto;
  overflow-y: scroll;
}

.winner-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.winner-modal .winner {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  width: 50%;
  min-height: 10%;
  max-height: 95%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.winnerSvg svg {
  height: 95vh !important;
}

/* width */
.sm-modal::-webkit-scrollbar,
.md-modal::-webkit-scrollbar,
.lg-modal::-webkit-scrollbar {
  display: none;
}

.lg-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
  border-radius: 10px;
  max-height: 95%;
  height: 100%;
  overflow-y: scroll;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.fw-copyright {
  font-size: 10px;
}

.offcanvas-start {
  width: 70%;
}

.btnBid {
  width: 40%;
}

.fwDrawer {
  height: 96%;
  display: flex;
  flex-direction: column;
}

.fwDrawer ul {
  list-style-type: none;
  padding-left: 0;
  margin-right: 10px;
}

.fwDrawer ul li {
  margin-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;

}

.fwDrawer ul li a {
  color: var(--black);
  font-weight: 500;
  width: 100%;
  display: block;
  padding: 5px 10px;
}

.fwDrawer ul li a:hover {
  background-color: var(--c-green-secondary);
  color: var(--white);
  width: 100%;
}

.categoryTabs li {
  margin-left: 15px;
  margin-right: 15px;
}

.categoryTabs li button {
  color: var(--c-green-secondary);
  border-bottom: 3px solid transparent !important;
}

.categoryTabs li button:hover {
  color: var(--c-green-secondary);
  transform: scale(1.03);
  transition: all 0.3s ease-in-out;
}

.categoryTabs .nav-pills .nav-link .active {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
}

.categoryTabs .active {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
  border-radius: 10px;
  padding: 8px 30px;
}

.memberHead {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.memberHead h3 {
  font-size: 46px;
  font-weight: 700;
  color: #727272;
}

.memberHead span {
  color: var(--c-green-secondary);
}

.memberHead img {
  height: 150px;
  width: 100%;
  object-fit: contain;
}

.memberImg img {
  height: 470px;
  width: 100%;
  object-fit: contain;
  text-align: end;
}

.memberFormHead h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--black);
}

.memberFormHead span {
  color: var(--c-green-secondary);
  font-size: 34px;
}

.memberFormHead img {
  height: 450px;
  width: 100%;
  object-fit: contain;
}

.successBox img {
  height: 110px;
  width: 100%;
  object-fit: contain;
}

.inspectionBg {
  background-image: url("../../images/inspectionBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 70%;
  height: 400px;
}

.membertick {
  height: 17px;
  width: auto;
  object-fit: contain;
  margin-left: 6px;
  margin-top: 5px;
}

.notMsg {
  font-size: 12px;
  text-align: center;
  border: 2px solid var(--c-green-secondary);
  display: inline;
  padding: 2px 10px;
  border-radius: 15px;
  background-color: red;
  color: var(--white);
}

.paymentHistory_header h6 {
  color: var(--white);
  font-size: 16px;
  font-weight: var(--fw-600);
  padding-left: 15px;
  padding-top: 10px;
}

.paymentHistory_list {
  border-radius: 10px;
}

.paymentHistory_list h6 {
  color: var(--black);
  font-size: 15px;
  font-weight: var(--fw-500);
  padding-left: 15px;
  margin: 0;
}

/* .paymentHistory_list Button {
    margin-left: 15px;
} */

.paymentHistory_list .Mui-disabled {
  opacity: 0.5 !important;
}

.paymentIcon {
  height: 40px;
  width: auto;
  padding-left: 15px;
  object-fit: contain;
}

.MuiAlert-filledSuccess {
  background-color: #5f8a63 !important;
}

.promImg {
  height: 360px !important;
  width: 370px;
  border-radius: 10px;
  object-fit: contain;
}

.MuiSvgIcon-root.Mui-active {
  color: var(--c-green-secondary) !important;
}

.MuiStepIcon-root.Mui-completed {
  color: #8bc815 !important;
}

.inspectionLegends div {
  font-weight: 600;
  cursor: pointer;
}

.inspection_legendImg {
  border: 2px solid var(--c-green-secondary);
  border-radius: 5px;
  height: 280px;
  width: 280px;
  text-align: center;
}

.inspection_legendImg img {
  height: 210px;
  object-fit: contain;
  box-shadow: 0px 0 10px 0 #0000001c;
  border-radius: 5px;
}

.inspectionLegends div:nth-of-type(1) {
  position: absolute;
  top: 32%;
  left: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 20%;
  height: 36%;
}

.inspectionLegends div:nth-of-type(2) {
  position: absolute;
  top: 30%;
  left: 0%;
  width: 10%;
  height: 40%;
}

.inspectionLegends div:nth-of-type(3) {
  position: absolute;
  top: 35%;
  left: 42%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 24%;
  height: 29%;
}

.inspectionLegends div:nth-of-type(4) {
  position: absolute;
  top: 32%;
  right: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 10%;
  height: 36%;
}

.inspectionLegends div:nth-of-type(5) {
  position: absolute;
  top: 29%;
  right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 10%;
  height: 42%;
}

.inspectionLegends div:nth-of-type(6) {
  position: absolute;
  top: 9%;
  left: 33.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 18%;
  height: 20%;
}

.inspectionLegends div:nth-of-type(7) {
  position: absolute;
  top: 9%;
  left: 51.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 15%;
  height: 20%;
}

.inspectionLegends div:nth-of-type(8) {
  position: absolute;
  top: 8%;
  left: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 20%;
  height: 15%;
}

.inspectionLegends div:nth-of-type(9) {
  position: absolute;
  bottom: 8%;
  left: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 20%;
  height: 15%;
}

.inspectionLegends div:nth-of-type(10) {
  position: absolute;
  bottom: 9%;
  left: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 19%;
  height: 20%;
}

.inspectionLegends div:nth-of-type(11) {
  position: absolute;
  bottom: 9%;
  right: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 16%;
  height: 20%;
}



.inspectionLegends div:nth-of-type(12) {
  position: absolute;
  bottom: 8%;
  right: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 16%;
  height: 18%;
}


.inspectionLegends div:nth-of-type(13) {
  position: absolute;
  top: 9%;
  right: 16%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 16%;
  height: 16%;
}




.inspectionLegends div:nth-of-type(14) {
  position: absolute;
  top: 1%;
  left: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 31%;
  height: 4%;
}

.inspectionLegends div:nth-of-type(15) {
  position: absolute;
  bottom: 4%;
  left: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 31%;
  height: 4%;
}


.inspection_legendImg p {
  color: #000000;
  font-weight: 500;
  margin-top: 15px;
}


.report_defect {
  background: white;
  border-radius: 50%;
  margin: 1.5px;
  padding: 1px;
  font-size: 10px;
  color: red;
  font-weight: bolder;
  border: none;
  outline: none;
}

.aboutDefect {
  font-size: 12px;
}

.inspectionImg1,
.inspectionImg2,
.inspectionImg3,
.inspectionImg4,
.inspectionImg5,
.inspectionImg6,
.inspectionImg7,
.inspectionImg8,
.inspectionImg9,
.inspectionImg10,
/* .inspectionImg11 {
  display: none;
} */

.timerModal .successModal {
  width: 90%;
  background-color: unset;
  box-shadow: unset;
  overflow: hidden;
}

.timerModal .MuiModal-backdrop {
  backdrop-filter: blur(20px) !important;
  background-color: #ffffff63 !important;
}

.modalLoaderImg {
  height: 150px;
  width: 100%;
  object-fit: contain;
}

.form-select:disabled {
  cursor: no-drop !important;
}

.searchBar_vcRadio .radio-label {
  border: 1px solid var(--c-green-secondary);
}

.radio-container {
  display: flex;
  gap: 20px;
}

.postAdForm .radio-label {
  padding: 6px 0px !important;
  width: 70px !important;
  text-align: center !important;
}

.radio-label {
  display: inline-block;
  padding: 6px 25px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 15px;
  color: #000000 !important;
}

.galleryImage {
  width: 50%;
}

.radio-input {
  display: none;
}

.radio-label:hover {
  background-color: transparent;
  border: 1px solid var(--c-green-secondary);
  color: var(--c-green-secondary) !important;
}

.searchBar_vcRadio .radio-input:checked+.radio-label {
  background-color: var(--c-green-secondary);
  color: #fff !important;
  border: 1px solid var(--c-green-secondary);
  background-image: url("../../images/searchTick.png");
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: 10% 50%;
  padding: 6px 25px 6px 35px !important;
}

.searchRadioTick {
  height: 25px;
  width: 25px;
  object-fit: contain;
  position: absolute;
  top: 4px;
  left: 10px;
  /* display: none; */
}

.radio-input:checked+.radio-label {
  background-color: var(--c-green-secondary);
  color: #fff !important;
  border: 1px solid var(--c-green-secondary);
}

.radio-input:checked+.radio-label:hover {
  background-color: var(--c-green-secondary);
  /* Change the color for selected + hover */
}

.radio-input:checked+.radio-label::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 255, 0);
  border-radius: 50%;
  animation: waterDrop 0.5s ease-in-out;
  z-index: -1;
}

@keyframes waterDrop {

  0%,
  100% {
    height: 0;
    width: 20px;
  }

  50% {
    height: 40px;
    width: 40px;
  }
}

.loadingModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background-color: transparent;
  box-shadow: unset;
  overflow: hidden;
  min-height: 10%;
  max-height: 95%;
  height: auto;
  text-align: center;
}

.inspectorForm_root label {
  font-size: 16px;
}

#mainImage {
  width: 100% !important;
  margin-top: 20px;
}

.customCheck_label {
  font-size: 15px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.customCheckYes {
  background-color: var(--c-green-secondary);
  color: var(--white);
  background-image: unset !important;
  background-size: unset !important;
  text-align: center !important;
  width: 100% !important;
  padding: 6px 0px !important;
  outline: unset !important;
  border: 1px solid var(--c-green-secondary) !important;
  box-shadow: unset !important;
}

.customCheckNo {
  background-color: transparent;
  color: var(--c-green-secondary);
  background-image: unset !important;
  background-size: unset !important;
  text-align: center !important;
  width: 100% !important;
  padding: 6px 0px !important;
  outline: unset !important;
  border: 1px solid var(--c-green-secondary) !important;
  box-shadow: unset !important;
}

.customCheckYes option {
  background-color: var(--white) !important;
  color: var(--black) !important;
}

.customCheck option:nth-child(1) {
  color: black !important;
}

/* .customCheck:focus-visible,
  .customCheck:focus{
    outline: unset !important;
    border: unset !important;
    box-shadow: unset !important;

  } */

.serviceDrop-main .nav-link .pad-0 {
  padding: 0 !important;
}

.popCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50px;
  margin: 5px;
}

.otp-field {
  flex-direction: row;
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid var(--black);
}

.otp-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
  display: none;
}

.resend {
  font-size: 12px;
}

.paymentPartnersImg img:nth-child(1) {
  height: 80px;
  object-fit: contain;
}

.paymentPartnersImg img:nth-child(2) {
  height: 80px;
  width: 150px;
  object-fit: contain;
}

.paymentPartnersImg img:nth-child(3) {
  height: 80px;
  width: 200px;
  object-fit: contain;
}

.paymentPartnersImg img:nth-child(4) {
  height: 80px;
  width: 200px;
  object-fit: contain;
}

#paymentpartners {
  font-size: 33px;
}

.postsPag .MuiPaginationItem-root.Mui-selected {
  background-color: var(--c-green-secondary) !important;
  color: var(--white);
}

.BecomeAlertMsg {
  background-color: var(--white);
}

.bgFrontCnic {
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 9 !important;
}

.bgFrontCnic::before {
  content: "";
  background-image: url("../../images/banners/front-side.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
}

.bgBackCnic {
  background: rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 9 !important;
}

.bgBackCnic::before {
  content: "";
  background-image: url("../../images/banners/back-side.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
}

.uploaderPreview {
  width: 400px;
  height: 250px;
  border: 2px solid #b80505 !important;
  border-radius: 15px !important;
  z-index: 99;
  margin: auto;
}

.uploaderPreview img {
  height: 246px;
  object-fit: contain;
  width: 100%;
}

.cnicDelBtn {
  background-color: var(--c-green-secondary);
  box-shadow: unset;
  border: unset;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  top: -15px;
  right: -15px;
}

.uploaderButtons {
  width: 400px;
  height: 250px;
  border: 2px dashed #b80505 !important;
  border-radius: 15px !important;
  z-index: 999999;
}

.uploaderButtons p {
  margin: 0;
  background-color: var(--c-green-secondary);
  color: var(--white);
  padding: 3px 20px;
  font-size: 30px;
  border-radius: 55px;
  z-index: 999999;
}

.cnicNote li {
  font-size: 14px;
}

.goFeatureBtn {
  background-color: #bdbd09;
  color: var(--white);
  font-weight: 600;
}

.grad-bg {
  background: rgb(168, 5, 5) !important;
  background: linear-gradient(180deg, #a80505 0%, #0b0505 100%) !important;
}

.grad-bg90 {
  background: rgb(168, 5, 5) !important;
  background: linear-gradient(90deg, #a80505 0%, #0b0505 100%) !important;
}

.Mui-selected {
  background: rgb(168, 5, 5) !important;
  background: linear-gradient(90deg, #a80505 0%, #0b0505 100%) !important;
}

.wantBecome {
  background: rgb(1, 4, 43) !important;
  background: linear-gradient(90deg,
      rgba(1, 4, 43, 1) 0%,
      rgba(184, 5, 5, 1) 35%,
      rgba(12, 1, 20, 1) 100%) !important;
  padding: 25px 30px;
  border-radius: 10px;
}

.wantBecome h4,
.wantBecome h5,
.wantBecome h3 {
  color: var(--white);
}

.wantBecome p {
  color: var(--white);
  font-size: 13px;
  margin: 0;
}

.suggestBg {
  background-image: url("../../images/car-suggest-bg.jpg");
  height: 750px;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 0;
}

.suggestBg::before {
  content: "";
  backdrop-filter: blur(5px);
  background: rgb(1, 4, 43);
  background: linear-gradient(90deg,
      rgb(1 4 43 / 56%) 0%,
      rgb(184 5 5 / 36%) 35%,
      rgb(12 1 20) 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -8;
}

.suggestCarMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.suggestbody {
  width: 80%;
  border-radius: 20px;
}

.SugbtnSelected {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
  text-transform: capitalize !important;
  width: 180px !important;
  font-weight: 600 !important;
}

.SugbtnUnselect {
  background-color: #eee !important;
  color: var(--black) !important;
  text-transform: capitalize !important;
  width: 180px !important;
  font-weight: 500 !important;
}

.sugBackBtn {
  background-color: unset;
  border: unset;
}

.sug2UnSelected {
  border: 2px solid transparent;
}

.sug2Selected {
  border: 2px solid var(--c-green-secondary);
}

.sug2SelectCheck {
  background-color: #20409a;
  width: 25px;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  top: -10px;
}

.sug2SelectCheck i {
  font-size: 14px;
}

.MuiSpeedDial-fab {
  background-color: var(--c-green-secondary) !important;
  min-width: 130px !important;
  max-width: 260px !important;
  padding: 0px 15px !important;
  width: auto !important;
  border-radius: 30px !important;
  animation: dailblink 1s infinite;
  transition: all ease-in-out;
}

.form-check-input:checked {
  background-color: var(--c-green-secondary);
  border-color: var(--c-green-secondary);
}

@keyframes dailblink {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.Blink-animation {
  animation: dailblink 1s infinite !important;
}

.SpeedDailBtn {
  align-items: flex-end !important;
}

.SpeedDailBtn .MuiSpeedDialAction-staticTooltipLabel {
  background-color: rgb(255, 255, 255) !important;
  text-align: center !important;
  width: 170px !important;
  font-weight: 500 !important;
  color: var(--black) !important;
  font-family: "Inter", sans-serif !important;
}

.DailInnerBtn {
  background-color: #21419b !important;
  height: 60px !important;
  width: 60px !important;
}

.supportPopup {
  min-height: 10vh !important;
  max-height: 70vh !important;
  height: auto;
  min-width: 150px !important;
  max-width: 385px !important;
  width: auto;
  background: rgb(58, 6, 213);
  background: linear-gradient(180deg, rgba(58, 6, 213, 1) 0%, rgba(58, 6, 213, 1) 12%, rgba(58, 6, 213, 1) 3%, rgb(94, 144, 209) 100%);
  border-radius: 20px;
  position: fixed;
  right: 30px;
  bottom: 120px;
  z-index: 99999999;
  overflow-y: scroll;
}

.messageBox h6 {
  font-size: 16px;
  font-weight: 600;
}

.messageBox p {
  font-size: 14px;
  font-weight: 400;
  color: #383838;
}

.supportPopup::-webkit-scrollbar {
  display: none;
}

.chatNav p {
  font-size: 13px;
  font-weight: 600;
}


.topSlider img {
  height: 600px;
  object-fit: cover;
}

.topSlider-Indicators {
  justify-content: start;
  column-gap: 20px;
  bottom: 10px !important;
}

.topSlider-Indicators [data-bs-target] {
  text-indent: unset !important;
  color: #ffffff !important;
  position: relative;
  font-size: 14px !important;
}

.topSlider-Indicators button {
  width: 170px !important;
  height: 6px !important;
}

.topSlider-Indicators button span {
  top: -30px !important;
  position: absolute !important;
  left: 0;
  right: 0;
  font-size: 16px !important;
}

.topSlider-Indicators .active {
  background-color: var(--c-green-secondary) !important;
}

.SliderSearchBar {
  height: 460px;
  width: 390px;
  position: absolute;
  top: 60px;
  right: 7%;
  /* background-color: #535307; */
}

.SliderSearchBar h4 {
  color: var(--white);
  font-weight: 700;
  font-size: 30px;
}

.sliderSearchTabs {
  justify-content: start;
  column-gap: 10px;
  width: 100%;
}

.sliderSearchTabs li {
  width: 48%;
}

.sliderSearchTabs li button {
  margin: auto;
  width: 100%;
  color: var(--black) !important;
  background-color: var(--white) !important;
  padding: 10px 0px;
  font-weight: 500 !important;
  border-radius: 5px !important;
}

.sliderSearchTabs li .active {
  background-color: var(--c-green-secondary) !important;
  color: var(--white) !important;
  font-weight: 500 !important;
  position: relative;
}

.sliderSearchTabs li .active::after {
  content: "";
  height: 20px;
  width: 20px;
  background-color: var(--white);
  display: block;
  position: absolute;
  left: 40%;
  bottom: -30px;
  transform: rotate(45deg);
}

.sliderSearchTab {
  background-color: var(--white);
  padding: 30px 20px;
  border-radius: 15px;
  margin-top: 20px;
}

.border2primary {
  border: 2px solid var(--c-green-secondary);
}

.searchTabsInput .MuiInputBase-root {
  background-color: var(--white) !important;
}

.searchTabsInput .MuiInputBase-root::after {
  border-bottom: 2px solid var(--c-green-secondary) !important;
}

.searchTabsInput .MuiFormLabel-root.Mui-focused {
  color: var(--c-green-secondary) !important;
}

.ourBid_img {
  height: 180px;
  object-fit: contain;
  max-width: 100%;
}

.wa_input:focus {
  background-color: var(--white) !important;
}

.footer_badges img {
  height: 70px;
  margin-right: 10px;
}

.footer_badges:nth-child(2) img {
  height: 60px;
}

.footer_badges h4 {
  margin-bottom: 0px;
}

.LegendIcon {
  background-color: var(--c-green-secondary) !important;
  height: 30px;
  min-width: 35px !important;
  border-radius: 10px !important;
  margin-top: 5px !important;
}

.bgLegend_Bonnet {
  background-image: url("../../images/bonnet.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_Roof {
  background-image: url("../../images/roof.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_Trunk {
  background-image: url("../../images/trunk.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50% 90%;
  background-position: center right;
}

.bgLegend_FrontDoor {
  background-image: url("../../images/front-door.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_BackDoor {
  background-image: url("../../images/back-door.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_FrontWing {
  background-image: url("../../images/front-wing.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_BackWing {
  background-image: url("../../images/back-wing.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}


.bgLegend_FrontBumper {
  background-image: url("../../images/front-bumper.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50% 90%;
  background-position: center right;
}


.bgLegend_RearBumper {
  background-image: url("../../images/rear-bumper.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50% 70%;
  background-position: center right;
}

.bgLegend_FrontWindSheildr {
  background-image: url("../../images/front-wind-sheild.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50% 90%;
  background-position: center right;
}

.bgLegend_APillar {
  background-image: url("../../images/pillar.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_BPillar {
  background-image: url("../../images/center-pillar.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_CPillar {
  background-image: url("../../images/c-pillar.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}


.bgLegend_DPillar {
  background-image: url("../../images/d-pillar.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.bgLegend_FootBand {
  background-image: url("../../images/footband.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50% 30%;
  background-position: center right;
}




.bgLegend_FrontRightDoor {
  background-image: url("../../images/front-right-door.png");
  height: 80px;
  background-repeat: no-repeat;
  background-size: 50% 90%;
  background-position: center right;
}

.topBanner_img {
  height: 300px;
  width: auto;
  object-fit: contain;
}

.StepperCount {
  color: #eeeeee;
  font-weight: bolder;
  font-size: 140px;
}

.OurSteppers {
  margin-left: -120px;
  margin-top: 60px;
}

.OurSteppers img {
  height: 42px;
  margin-bottom: 15px;
}

.OurSteppers h6 {
  font-size: 22px;
  font-weight: 700;
}

.PostSwitch {
  height: 60px;
}

.PostSwitch input {
  height: 30px !important;
  width: 60px !important;
}

/* Responsive Breakpoints */

@media (max-width: 1210px) {
  .postAd_bg {
    height: 170px;
  }
}

@media (max-width: 992px) {

  .StepperCount {
    color: #eeeeee;
    font-weight: bolder;
    font-size: 100px;
  }

  .OurSteppers {
    margin-left: -105px;
    margin-top: 30px;
  }

  .OurSteppers img {
    height: 42px;
    margin-bottom: 15px;
  }

  .OurSteppers h6 {
    font-size: 14px;
    font-weight: 700;
  }


  .SpeedDailBtn {
    bottom: 85px !important;
  }

  .supportPopup {
    bottom: 150px !important;
  }


  .paymentPartnersImg img:nth-child(1) {
    width: 130px;
    margin-bottom: 20px;
  }

  .paymentPartnersImg img:nth-child(2) {
    width: 130px;
    margin-bottom: 20px;
  }

  .paymentPartnersImg img:nth-child(3) {
    width: 130px;
    margin-bottom: 20px;
  }

  .paymentPartnersImg img:nth-child(4) {
    width: 130px;
    margin-bottom: 20px;
  }

  .inspectionBg {
    height: 150px;
  }

  .postAd_bg {
    height: 150px;
  }

  .biddingBannerMain {
    height: 260px;
  }

  .myUserDetail_Main {
    background-image: unset !important;
    padding-top: 25px;
  }

  .myUserDetail_Main .profileArea {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .paymentIcon {
    height: 25px;
  }

  .refundBtn {
    margin-top: 15px;
  }

  .d-992-none {
    display: none;
  }

  .myAds_options ul {
    display: flex;
    overflow-y: scroll;
  }

  .myAds_options ul::-webkit-scrollbar {
    display: none;
  }

  .myAds_options li {
    width: 150px;
  }

  .myAds_options li a {
    width: 150px;
    display: block;
  }

  .myAds_options ul li button {
    font-size: 14px !important;
    text-align: center !important;
  }

  .BtnActive button {
    background-color: var(--white) !important;
    color: var(--c-green-secondary) !important;
    border-bottom: 2px solid var(--c-green-secondary);
  }

  .inspectorStepper .MuiStepLabel-labelContainer {
    display: none;
  }

  .inspectorStepper .MuiStepLabel-iconContainer,
  .inspectorStepper .MuiStep-root {
    padding-right: 0 !important;
  }

  .report-customer-info {
    font-size: 14px;
    text-transform: capitalize;
  }

  .myAds_options {
    margin-left: 15px;
    margin-right: 15px;
  }

  .Myads_photos {
    margin-bottom: 10px;
  }

  .biddingHeader div {
    font-size: 12px;
  }

  .biddingTImer {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }

  .biddingTImer h4 {
    font-size: 16px;
  }

  .biddingTImer p {
    font-size: 20px;
  }

  .privacyText {
    font-size: 11px;
  }

  .fw-copyright {
    font-size: 13px;
  }

  .sm-modal {
    width: 90%;
    min-height: 50%;
    max-height: 80%;
    height: auto;
    top: 45%;
  }


  .myLogin {
    width: 90%;
    min-height: 50%;
    max-height: 80%;
    height: auto;
    top: 45%;
  }

  footer {
    margin-bottom: 85px !important;
  }

  .inspectionForm label {
    font-size: 13px;
  }

  .inspectionForm select {
    font-size: 13px;
  }

  .inspectionForm input {
    font-size: 13px;
  }

  .inspectionForm .form-text {
    font-size: 10px;
  }

  .inspectionForm textarea {
    font-size: 10px;
  }

  .inspectionForm button {
    padding: 5px 15px;
    font-size: 10px;
  }

  .postAdForm label {
    font-size: 13px;
  }

  .postAdForm select {
    font-size: 13px;
  }

  .postAdForm input {
    font-size: 13px;
  }

  .postAdForm .form-text {
    font-size: 10px;
  }

  .postAdForm textarea {
    font-size: 10px;
  }

  .postAdForm button {
    padding: 5px 15px;
    font-size: 13px;
  }

  .postAdForm .input-group span {
    font-size: 10px;
    padding: 3px 5px;
  }

  .bottomNagivation_res {
    display: block !important;
  }

  .d-response {
    display: none !important;
  }

  .adPost img {
    height: 120px !important;
  }

  .featured-badge {
    font-size: 10px !important;
  }

  .searchBarArea {
    border-radius: 10px;
  }

  .searchBarArea Button {
    background-color: var(--c-green-secondary) !important;
    color: var(--white) !important;
  }

  .imgStyle {
    min-height: 90px;
    padding: 15px !important;
    background-size: 90%;
  }

  f .imgStyle h2 {
    font-weight: 600 !important;
    font-size: 11px !important;
  }

  .imgStyle button {
    font-size: 9px !important;
    text-align: start;
    padding: 5px 15px !important;
  }

  .imgStyle button svg {
    display: none !important;
  }

  .ad-share-btns a {
    padding: 0px 6px !important;
  }

  .ad-share-btns i {
    font-size: 11px !important;
  }

  .AdbBanner {
    min-height: 130px !important;
  }

  .responseTab {
    flex-direction: row !important;
  }

  .w-md-unset {
    width: unset !important;
  }

  .fw-navbar {
    position: absolute !important;
    background-color: var(--white) !important;
    right: 25px;
    width: 180px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 99888559;
    border-radius: 5px;
    box-shadow: 0 0 5px 0px #0000001f;
  }

  .vehicleTab2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .vehicleTab2 h4 {
    font-size: var(--fs-15) !important;
    margin: 0;
  }

  .vehicleTab2 p {
    font-size: var(--fs-14) !important;
  }

  .vehicleBid_More {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vehicleBid_More h4 {
    font-size: var(--fs-16) !important;
  }

  .actionBids_btn {
    padding-top: 0 !important;
  }

  .vehicleImg {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: unset !important;
  }
}

@media (max-width: 768px) {

  .checkReport_Btn {
    text-transform: unset !important;
    width: 90% !important;
    margin: auto;
  }


  #appointment {
    margin-top: 20px;
  }

  .inspectionHead {
    align-items: start !important;
  }

  .inspectionBg {
    height: 120px;
  }

  .postAd_bg {
    height: 120px;
  }

  .paymentHistory_list h6 {
    padding: 0;
  }

  .biddingBannerMain {
    height: 130px;
  }

  .refundBtn {
    margin-top: 5px;
    text-align: end !important;
  }

  .refundBtn button {
    font-size: 12px !important;
    padding: 5px 10px !important;
  }

  .successModal {
    width: 80% !important;
  }

  .successModal h5 {
    font-size: 15px;
  }

  .successModal p {
    font-size: 13px;
  }

  .imageClickDot {
    width: auto;
    height: auto;
    background-color: #b80505;
    border-radius: 50%;
  }

  .emailBar input::placeholder {
    font-size: 12px;
    text-transform: capitalize;
  }

  .inspectReportCar {
    height: 200px !important;
    width: 250px !important;
    padding-bottom: 3px;
    object-fit: contain;
  }

  .report-customer-info {
    font-size: 14px;
    text-transform: capitalize;
  }

  .bidinfo1,
  .bidinfo2 {
    flex-direction: column;
  }

  .bidPriceInc {
    width: 70%;
  }

  .btnBid {
    width: 70%;
    margin-top: 15px;
  }

  .postDetailss_specs img {
    height: 25px;
    object-fit: contain;
  }

  .postDetailss_specs h6 {
    font-size: 12px;
  }

  .adBody h4 {
    font-size: 14px;
  }

  .adBody .adlocation {
    font-size: 12px;
  }

  .footer-services h5 {
    font-size: 16px !important;
  }

  .footer-services ul li {
    font-size: 11px !important;
  }

  .preHeader_authBtns button,
  .callUs h5 {
    font-size: 14px;
  }

  .modalBody_area h3 {
    font-size: 26px;
  }

  .modalBody_area h6 {
    font-size: 16px;
  }

  .modalBody_area .connect_para {
    font-size: 12px;
  }

  .login_inputStyle input::placeholder {
    font-size: 12px;
  }

  .login_inputStyle input {
    font-size: 11px;
  }

  .have_account {
    font-size: 12px;
  }

  .have_account button {
    font-weight: 700;
  }

  .login_forgot {
    padding: 0;
  }

  .login_forgot a {
    font-size: 12px;
  }

  .model_loginBTn {
    font-weight: 700;
  }

  .fw-copyright {
    font-size: 10px;
  }

  .inspectionHead h2 {
    font-size: 14px !important;
    padding-bottom: 0 !important;
  }

  .inspectionHead p {
    font-size: 12px !important;
    margin: 0;
    width: 50%;
    text-align: start;
  }

  .inspectionHead .setSer {
    padding-top: 2%;
    font-size: 10px;
  }

  .inspectionHead .setSer a {
    font-size: 9px;
    padding: 5px 15px !important;
    font-family: 500 !important;
  }

  .insImg {
    height: 150px !important;
    width: 100%;
    object-fit: contain;
  }

  .userDetails {
    display: none;
  }

  .inspectionForm label {
    font-size: 9px;
  }

  .inspectionForm select {
    font-size: 10px;
  }

  .inspectionForm input {
    font-size: 9px;
  }

  .inspectionForm .form-text {
    display: none;
  }

  .inspectionForm textarea {
    font-size: 10px;
  }

  .inspectionForm button {
    padding: 5px 15px;
    font-size: 10px;
  }

  /* post form */

  .postAdForm label {
    font-size: 9px;
  }

  .postAdForm select {
    font-size: 10px;
  }

  .postAdForm input {
    font-size: 9px;
  }

  .postAdForm .PostSwitch input {
    font-size: 16px !important;
  }

  .postAdForm .PostSwitch_label {
    font-size: 16px !important;
  }

  .postAdForm .form-text {
    font-size: 9px;
  }

  .postAdForm textarea {
    font-size: 10px;
  }

  .postAdForm button {
    padding: 5px 15px;
    font-size: 10px;
  }

  .postAdForm .input-group span {
    font-size: 10px;
  }

  .md-radioStyle {
    display: flex;
    align-items: center;
  }

  .md-radioStyle input {
    margin-right: 8px;
  }

  .md-radioStyle2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .md-radioStyle2 input {
    margin-right: 8px;
  }

  .vehicleTab2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .vehicleTab2 h4 {
    font-size: var(--fs-16) !important;
    margin: 0;
  }

  .vehicleTab2 p {
    font-size: var(--fs-16) !important;
  }

  .vehicleBid_More {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .actionBids_btn {
    padding-top: 0 !important;
  }

  .callUs a {
    font-size: var(--fs-12);
  }

  .Services-subMenu {
    left: 25%;
  }

  .serviceDrop:hover {
    cursor: pointer;
    border-bottom: 2px solid transparent !important;
  }

  .Services-subMenu {
    min-width: 10px;
    max-width: fit-content;
    width: auto;
  }

  .preHeader_authBtns a {
    font-size: var(--fs-12);
  }

  .bg-img {
    height: unset;
  }

  .topSec1 h3 {
    font-size: var(--fs-25);
  }

  .topSec1 h5 {
    font-size: var(--fs-18);
  }

  .fw-searchBar .MuiTabs-flexContainer .MuiTab-root {
    font-size: var(--fs-12);
  }

  .searchCar,
  .fw-searchbtn {
    width: 100%;
  }

  .categpryBtn {
    height: 90px !important;
  }

  .categpryBtn img {
    height: 25px;
  }

  .categpryBtn h6,
  .post-fw-ad p {
    font-size: var(--fs-12);
  }

  .aboutUs_para {
    font-size: var(--fs-13);
    color: var(--blue-primary);
    width: 95%;
  }

  .bg-post {
    background-image: unset !important;
  }

  .post-fw-ad :is(h3, p) {
    color: var(--blue-primary) !important;
  }

  .post-fw-ad h3 {
    font-size: var(--fs-18);
    text-align: center;
    font-weight: var(--fw-700);
    padding-top: 10px;
  }

  .post-fw-ad button {
    background-color: var(--blue-primary);
    color: var(--white);
    font-weight: var(--fw-600);
    text-transform: var(--capitalize);
    border: 2px solid var(--white);
    border-radius: var(--br-5);
  }

  .fw-title {
    font-size: var(--fs-19);
  }

  .adPost img {
    height: 120px;
  }

  .adBody h5 {
    font-size: var(--fs-15);
  }

  .categoryTabs li {
    margin: 0;
  }

  .makeBtn h6 {
    font-size: 14px;
  }

  .md-modal {
    width: 85%;
  }

  .promImg {
    height: 200px;
    width: 100%;
  }

  .userMobileDetails p {
    color: var(--black) !important;
    opacity: unset !important;
    padding: 2px 10px;
    font-size: var(--fs-14);
  }

  .memberHead h3 {
    font-size: 18px;
  }

  .memberHead img {
    height: 100px;
    width: 100%;
    object-fit: contain;
  }

  .memberImg img {
    height: 250px;
    width: 100%;
    object-fit: contain;
    text-align: end;
  }

  .memberFormHead img {
    height: 250px;
  }

  .memberFormHead h2 {
    font-size: 26px;
    text-align: center;
  }

  .makeBtn img {
    height: 45px;
  }

  /* .categpryBtn {
        padding: 0 !important;
    } */
}

@media (max-width: 768px) {
  .btntextSize {
    font-size: 12px !important;
  }
}


@media (max-width: 600px) {
  .postAd_bg {
    height: 100px;
  }

  .searchBg {
    height: 30vh;
  }

  .searchBar {
    background-color: unset;
    border-radius: 10px;
    backdrop-filter: unset;
    align-items: center;
  }

  .searchBar input {
    background-color: #ffffff9c;
    border-radius: 0px !important;
    /* margin-bottom: 5px; */
    padding: 7px 10px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    font-size: 14px !important;
  }

  .searchBar input::placeholder {
    font-size: 11px;
  }

  .searchBar input:is(:focus, :focus-visible, :focus-within) {
    background-color: #ffffff9c !important;
  }

  .bgRed10 {
    backdrop-filter: unset !important;
  }

  .searchBar .btnArea button {
    font-size: 10px !important;
    padding: 10px 20px !important;
    border-radius: 0px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

@media (max-width: 600px) {
  .galleryImage {
    width: 98%;
  }
}



@media (max-width: 464px) {

  .StepperCount {
    color: #eeeeee;
    font-weight: bolder;
    font-size: 80px;
  }

  .SpeedDailBtn,
  .supportPopup {
    right: 10px;
  }

  .postAd_bg {
    height: 80px;
  }

  .section-titles {
    font-size: var(--fs-20);
  }

  .emailBar {
    box-shadow: unset !important;
  }

  .emailBar input {
    box-shadow: 0 0 20px 0 #00000015 !important;
    margin-bottom: 10px;
  }

  .emailBar .btnArea {
    width: 100%;
    text-align: center;
  }

  .paymentHistory_header h6 {
    font-size: 12px;
  }

  .paymentHistory_list h6 {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .MuiSvgIcon-root {
    height: 20px !important;
    width: 20px !important;
  }

  .MuiStepIcon-text {
    font-size: 12px !important;
  }

  .paymentHistory_header h6 {
    font-size: 10px;
  }
}

@media print {
  .print-hidden {
    display: none !important;
  }

  .reportAccTab Button {
    display: none;
  }

  .reportAccordins .accordion-button:not(.collapsed),
  .reportAccordins .accordion-button {
    background-color: #dee2e6;
    print-color-adjust: exact;
  }

  .inspectionPrint {
    width: 775px;
  }
}